import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch,useSelector } from 'react-redux';
import Error from "./pages/Error";
import Header from "./components/Header";
import Footer from "./components/Footer";
import IsAdminHeader from "./components/IsAdminHeader";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
// import Staff from "./pages/Staff";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Profile from "./pages/Profile";
import AdminUsers from "./pages/AdminUsers";
import UserDetail from "./pages/AdminUser";
// import Mentions from "./pages/Mentions";
// import Cgu from "./pages/Cgu";
import ForgotPassword from "./pages/ForgotPass";
import ResetPassword from "./pages/GenerateNewPassword";
import AdminCreateSignupPage from "./pages/AdminCreateSignupPage";
import { useEffect } from 'react';
import { checkTokenExpiration, selectToken } from './feature/user.Slice';
import ContactForm from "./pages/ContactForm";
import BounceTable from "./pages/AdminBounces";

/**
 * @file
 * @summary App est le composant racine de l'application.
 * @description Il contient le routeur qui définit les différentes routes de l'application.
 * 
 * ## Routes
 * Les routes sont les suivantes :
 * - **"/"** : la page d'accueil, accessible à tous les utilisateurs.
 * - **"/adminBounces"** : la page des bounces, accessible uniquement aux utilisateurs administrateurs.
 * - **"/admin/users"** : la page d'administration des utilisateurs, accessible uniquement aux utilisateurs administrateurs.
 * - **"/admin/CreateSignupPage"** : la page de création de la page d'inscription, accessible uniquement aux utilisateurs administrateurs.
 * - **"/admin/users/:id"** : la page de détail de l'utilisateur, accessible uniquement aux utilisateurs administrateurs.
 * - **"/admin"** : la page d'administration, accessible uniquement aux utilisateurs administrateurs.
 * - **"/forgot"** : la page de réinitialisation du mot de passe, accessible à tous les utilisateurs.
 * - **"/reset/:token"** : la page de réinitialisation du mot de passe avec le token, accessible à tous les utilisateurs.
 * - **"/Mentions"** : la page des mentions légales, accessible à tous les utilisateurs.
 * - **"/Cgu"** : la page des conditions générales d'utilisation, accessible à tous les utilisateurs.
 * - **"/Profile"** : la page de profil, accessible uniquement aux utilisateurs connectés.
 * - **"/ContactForm"** : la page de contact, accessible uniquement aux utilisateurs connectés.
 * - **"/signin"** : la page de connexion, accessible uniquement aux utilisateurs non connectés.
 * - **"/signup/:encryptedEntreprise/:encryptedSiret"** : la page d'inscription, accessible uniquement aux utilisateurs non connectés. Les informations de l'entreprise et du SIRET peuvent être pré-remplies à partir de l'URL si elles sont fournies sous forme cryptée.
 * - **"/dashboard"** : la page de tableau de bord, accessible uniquement aux utilisateurs administrateurs.
 * - **"/error"** : la page d'erreur, accessible à tous les utilisateurs.
 * - **"*"** : la page d'erreur, accessible à tous les utilisateurs.
 * 
 * ## Redirections
 * - Si un utilisateur non connecté tente d'accéder à une page réservée aux utilisateurs connectés, il est redirigé vers la page de connexion.
 * - Si un utilisateur connecté tente d'accéder à la page de connexion ou à la page d'inscription, il est redirigé vers la page d'accueil.
 * - Si un utilisateur non administrateur tente d'accéder à une page réservée aux administrateurs, il est redirigé vers la page de connexion.
 */

function App() {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const user = useSelector((state) => state.user.user && state.user.user.user);


  const dispatch = useDispatch();
const token = useSelector(selectToken);

useEffect(() => {
  dispatch(checkTokenExpiration());
}, [dispatch, token]);

  //console.log(user);
  //console.log(isLoggedIn);
  return (
    <BrowserRouter>
     {isLoggedIn && <IsAdminHeader />}
      <Header />
      <Routes>
        <Route path="/" element={isLoggedIn ? <Home /> : <Navigate to="/signin" />} />
        <Route path="/admin/users" element={user && user.is_admin ? <AdminUsers /> : <Navigate to="/signin" />} />
        <Route path="/admin/CreateSignupPage" element={user && user.is_admin ? <AdminCreateSignupPage /> : <Navigate to="/signin" />} />
        <Route path="/admin/users/:id" element={isLoggedIn && user.is_admin ? <UserDetail /> : <Navigate to="/signin" />} />
        <Route path="/admin" element={isLoggedIn && user.is_admin ? <AdminUsers /> : <Navigate to="/signin" />} />
        <Route path="/adminBounces" element={isLoggedIn && user.is_admin ? <BounceTable /> : <Navigate to="/signin" />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/reset/:token" element={<ResetPassword />} />
        {/* <Route path="/staff" element={isLoggedIn ? <Staff /> : <Navigate to="/signin" />} /> */}
        {/* <Route path="/Mentions" element={isLoggedIn ? <Mentions /> : <Navigate to="/signin" />} />
        <Route path="/Cgu" element={isLoggedIn ? <Cgu /> : <Navigate to="/signin" />} /> */}
        <Route path="/Profil" element={isLoggedIn ? <Profile /> : <Navigate to="/signin" />} />
        <Route path="/ContactForm" element={isLoggedIn ? <ContactForm /> : <Navigate to="/signin" />} />
        <Route path="/signin" element={isLoggedIn ? <Navigate to="/" /> : <Signin />} />
        <Route path="/signup/:encryptedEntreprise/:encryptedSiret" element={isLoggedIn ? <Navigate to="/" /> : <Signup />} />
        <Route path="/dashboard" element={isLoggedIn && user.is_admin ? <Dashboard /> : <Navigate to="/signin" />} />
        <Route path="/error" element={<Error />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
