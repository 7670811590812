import React, { useState } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import Modal from 'fast-modal-library';
import { useSelector } from 'react-redux';

const apiPrefix = process.env.REACT_APP_API_PREFIX;

const ImportCsvExcel = () => {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(1); // Commence à 1 pour ignorer l'en-tête CSV
    const token = useSelector((state) => state.user.token);

    const hide = () => {
        setShowModal(false);
        setData([]);
        window.location.reload();
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (evt) => {
            if (file.type === 'text/csv') {
                Papa.parse(evt.target.result, {
                    complete: (results) => {
                        const nonEmptyRows = results.data.filter(row => row.some(item => item));
                        setData(oldData => [...oldData, ...nonEmptyRows]);
                    },
                });
            } else if (file.type.includes('spreadsheetml')) {
                const workbook = XLSX.read(evt.target.result, { type: 'binary' });
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const nonEmptyRows = jsonData.filter(row => row.some(item => item));
                setData(oldData => [...oldData, ...nonEmptyRows]);
            }
        };

        reader.readAsBinaryString(file);
    };

    const handleRegisterUsers = async (startIndex = 1) => {
        for (let i = startIndex; i < data.length; i++) {
            const row = data[i];
            const [username, password, firstname, lastname, company, siret, chefEntreprise, active] = row;

            const formData = {
                username,
                password,
                firstname,
                lastname,
                company,
                siret,
                chefEntreprise: chefEntreprise === 'true' || chefEntreprise === true,
                active: active === 'true' || active === true
            };

            try {
                const response = await fetch(apiPrefix + 'register', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                if (!response.ok) {
                    const errorMessage = await response.json();
                    if (response.status === 502) {
                        setCurrentUser(formData); // Enregistre les données de l'utilisateur pour confirmation
                        setShowConfirmModal(true); // Affiche la modale de confirmation
                        setCurrentIndex(i + 1); // Enregistre l'index du prochain utilisateur
                        return; // Interrompt la boucle pour attendre la confirmation
                    } else {
                        console.error('Erreur lors de l\'inscription:', errorMessage.error);
                    }
                } else {
                    console.log('Utilisateur inscrit avec succès');
                }
            } catch (error) {
                console.error('Erreur lors de la tentative d\'inscription:', error);
            }
        }

        setShowModal(true); // Affiche la confirmation finale une fois la boucle terminée
    };

    const handleConfirmUpdate = async (confirm) => {
        if (confirm && currentUser) {
            try {
                const response = await fetch(apiPrefix + 'admin/updateimport', {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(currentUser),
                });

                if (response.ok) {
                    console.log('Utilisateur mis à jour avec succès.');
                } else {
                    const errorMessage = await response.json();
                    console.error('Erreur lors de la mise à jour de l\'utilisateur:', errorMessage.error);
                }
            } catch (error) {
                console.error('Erreur lors de la mise à jour:', error);
            }
        }

        setCurrentUser(null);
        setShowConfirmModal(false);
        handleRegisterUsers(currentIndex); // Reprend la boucle à partir du prochain utilisateur
    };

    return (
        <div className='importCsv-container'>
            <input type="file" accept=".csv, .xlsx" onChange={handleFileUpload} />
            {data.length > 0 && (
                <>
                    <button onClick={() => handleRegisterUsers(1)}>Inscrire les utilisateurs</button>
                    <div className='table-container'>
                        <table>
                            <tbody>
                                {data.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {row.map((item, itemIndex) => (
                                            <td key={itemIndex}>{item}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
            {showModal && (
                <Modal contentModal="Inscription réussie !" hide={hide} />
            )}
            {showConfirmModal && (
                <Modal 
                    contentModal={
                        <div>
                            <p>L'utilisateur suivant existe déjà. Voulez-vous mettre à jour ses informations avec les données suivantes ?</p>
                            <ul>
                                <li><strong>Email:</strong> {currentUser?.username}</li>
                                <li><strong>Mot de passe:</strong> {currentUser?.password}</li>
                                <li><strong>Prénom:</strong> {currentUser?.firstname}</li>
                                <li><strong>Nom:</strong> {currentUser?.lastname}</li>
                                <li><strong>Entreprise:</strong> {currentUser?.company}</li>
                                <li><strong>SIRET:</strong> {currentUser?.siret}</li>
                                <li><strong>Chef d'Entreprise:</strong> {currentUser?.chefEntreprise ? 'Oui' : 'Non'}</li>
                                <li><strong>Actif:</strong> {currentUser?.active ? 'Oui' : 'Non'}</li>
                            </ul>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                <button onClick={() => handleConfirmUpdate(true)}>Confirmer</button>
                                <button onClick={() => handleConfirmUpdate(false)} style={{ marginLeft: '10px' }}>Annuler</button>
                            </div>
                        </div>
                    }
                    hide={() => setShowConfirmModal(false)}
                />
            )}
        </div>
    );
};

export default ImportCsvExcel;
