import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
// import employeeReducer from "../feature/employe.slice";
import userReducer from "../feature/user.Slice";

const persistConfig = {
    key: 'root',
    storage,
};

const rootReducer = combineReducers({
    // employee: employeeReducer,
    user: userReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;