import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AutoRedirect from '../components/Autologin';
import { isMobile } from 'react-device-detect';
import OptIn from '../components/Optin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faStore, faEnvelope, faNewspaper } from '@fortawesome/free-solid-svg-icons';

/**
 * @file
 * @module Pages/Home
 * @description Home est une page qui accueille l'utilisateur lorsqu'il est connecté.
 * - Elle affiche un message de bienvenue personnalisé avec le nom de l'utilisateur.
 * - Elle contient également un lien vers le site de billetterie.
 * - L'état de connexion de l'utilisateur est récupéré du store Redux.
 */

const Home = () => {
    const user = useSelector((state) => state.user.user);
    const isLoggedIn = useSelector((state) => state.user.token !== null);



    // Vérifier si user et ses propriétés imbriquées existent avant d'accéder à optin
    const optInStatus = user?.user?.optin;
    const username = user?.user?.username;
    const firstname = user?.user?.firstname;



    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token) {
            const newUrl = `${window.location.origin}${window.location.pathname}/${token}`;
            window.history.replaceState(null, null, newUrl);
        }
    }, []);

    const redirectUrl = isMobile ? "https://app.reducce.fr/autologin/" : "http://ca-cpourmoi.reducce.fr/autologin/";

    return (
        <div className='Home'>
            <div className='hero-section'>
                <div className='hero-content'>
                    {isLoggedIn && <h2 className='welcome-message'>Bonjour {firstname}</h2>}
                    <h1>Bienvenue sur votre <br /> Comité d'entreprise !</h1>
                    <p>Découvrez des offres exclusives sur vos achats du quotidien.</p>
                    <AutoRedirect redirectUrl={redirectUrl} />
                </div>
            </div>
            <div className='card-section'>
                <div className='card'>
                    <h3><span className='icon'><FontAwesomeIcon icon={faUser} /></span>Mon compte</h3>
                    <p>Bienvenue dans votre espace personnel. Ici, vous pouvez gérer vos informations personnelles et les mettre à jour, de manière simple et sécurisée.</p>
                    <Link to="/Profil">
                        <button className='card-button'>Mon compte</button>
                    </Link>
                </div>
                <div className='card'>
                    <h3><span className='icon'><FontAwesomeIcon icon={faStore} /></span>Référencer une enseigne</h3>
                    <p>Vous ne trouvez pas une enseigne que vous aimez ? Faites-nous part de vos suggestions ! Nous la contacterons pour l'ajouter à nos offres CE.</p>
                    <Link to="/ContactForm">
                        <button className='card-button'>Remplir le formulaire</button>
                    </Link>
                </div>
                <div className='card'>
                    <h3><span className='icon'><FontAwesomeIcon icon={faEnvelope} /></span>Contact</h3>
                    <p>Pour toute réclamation sur la billetterie ou le paiement, contactez Ski Loisirs Diffusion au
                        <br /> 04 72 40 28 28 ou par mail à serviceclient@reducce.fr.Pour toute autre demande :</p>
                    <a href="mailto:contact@ca-cpourmoi.com">
                        <button className='card-button'>Contactez nous !</button>
                    </a>
                </div>
                <div className='card'>
                    <h3><span className='icon'><FontAwesomeIcon icon={faNewspaper} /></span>Newsletter</h3>
                    <p>Ne manquez rien ! Inscrivez-vous pour recevoir les bons plans de la semaine et des offres exclusives directement dans votre boîte mail.</p>
                    {optInStatus !== undefined && (
                        <button className='card-button'><OptIn username={username} optIn={optInStatus} /></button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Home;
