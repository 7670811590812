import React from 'react';
import { Link } from 'react-router-dom';
import Stats from '../components/Stats';

/**
 * @file
 * @module Pages/Dashboard
 * @description Dashboard est une page qui affiche le tableau de bord de l'administrateur.
 * - Elle contient des liens vers d'autres pages d'administration, comme la gestion des utilisateurs et la création d'une URL de création de compte.
 * - Elle contient également un lien vers le Back Office ReducCe.
 * - Cette page utilise le composant Stats pour afficher les statistiques de l'application.
 * 
 */

const Dashboard = () => {
    return (
        <div className="dashboard-container">
            <h1>Bienvenue sur la page d'accueil de l'administrateur</h1>
            <ul>
                <li><Link to="/admin/users">Gérer les utilisateurs</Link></li>
                <li><Link to="/admin/CreateSignupPage">Créer une URL de création de compte</Link></li>
                <li><a href="https://admin.reducce.fr/login?returnUrl=%2Fadministrators" target="_blank" rel="noopener noreferrer">Back Office ReducCe</a></li>
                <li><a href="https://app.brevo.com/" target="_blank" rel="noopener noreferrer">App Brevo</a></li>
                <li><Link to="/adminBounces">Retour Brevo mail invalid</Link></li>
               
                
                {/* <li><Link to="/admin/products">Gérer les produits</Link></li>
                <li><Link to="/admin/orders">Gérer les commandes</Link></li> */}
            </ul>
                    <Stats />
        </div>
    );
};

export default Dashboard;