import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'fast-modal-library';

const apiPrefix = process.env.REACT_APP_API_PREFIX;

/**
 * @file
 * @module Pages/ContactForm
 * @description ContactForm est une page qui permet à un utilisateur de contacter l'administrateur pour référencer une enseigne.
 * - L'utilisateur doit remplir un formulaire avec ses coordonnées et les informations sur l'enseigne à référencer.
 * - Lorsque l'utilisateur soumet le formulaire, un e-mail est envoyé à l'administrateur avec les informations fournies.
 * - Cette page utilise le hook useState de React pour gérer l'état des champs de saisie et de l'affichage de la modale.
 * - Cette page utilise le hook useSelector de 'react-redux' pour accéder aux informations de l'utilisateur connecté.
 */

const ContactForm = () => {
    const user = useSelector((state) => state.user.user.user);
    const [firstname] = useState(user.firstname);
    const [lastname] = useState(user.lastname);
    const [username] = useState(user.username);
    const [showModal, setShowModal] = useState(false);

    const [formData, setFormData] = useState({
        userName: `${firstname} ${lastname}`,
        userEmail: username,
        userPhone: '',
        storeName: '',
        storeCity: '',
        contactName: '',
        contactPhone: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(apiPrefix+'contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            const data = await response.json();
            if (response.ok) {
                setShowModal(true); // Affiche la modale après une soumission réussie
            } else {
                throw new Error(data.error);
            }
        } catch (error) {
            alert('Une erreur est survenue lors de l\'envoi du message.');
        }
    };

    const hideModal = () => {
        setShowModal(false);
        window.location.href = '/'; // Redirection vers la page d'accueil
    };

    return (
        <div className="contact-form-container">
            <h1>Référencer une enseigne</h1>
            <p>
                Si une enseigne que vous aimez ne figure pas sur votre plateforme…
            </p>
            <p>
                Pensez à faire référencer des enseignes qui vous intéressent…
            </p>
            <p>
                Si vous avez l’habitude de faire vos achats dans une enseigne qui ne figure pas parmi les nombreuses offres de Ca c’est pour MOI!, envoyez-nous vos demandes de référencement par le biais du formulaire ci-dessous. Nous la contacterons afin de savoir si l’enseigne propose des offres spéciales Comité d’entreprise auquel cas nous ferons notre possible pour la référencer.
            </p>
            <p>
                Vous pouvez également accéder à ce formulaire sur toutes les pages, dans le menu du bas.
            </p>
            <p>
                N’hésitez pas !!!
            </p>
            <form onSubmit={handleSubmit} className="contact-form">
                <h3>1. À propos de vous</h3>
                <div className="form-group">
                    <label>Nom*</label>
                    <input
                        type="text"
                        name="userName"
                        value={formData.userName}
                        onChange={handleChange}
                        required
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Adresse mail*</label>
                    <input
                        type="email"
                        name="userEmail"
                        value={formData.userEmail}
                        onChange={handleChange}
                        required
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Téléphone</label>
                    <input
                        type="text"
                        name="userPhone"
                        value={formData.userPhone}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <h3>2. À propos de l'enseigne à référencer</h3>
                <div className="form-group">
                    <label>Nom de l'enseigne*</label>
                    <input
                        type="text"
                        name="storeName"
                        value={formData.storeName}
                        onChange={handleChange}
                        required
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Ville*</label>
                    <input
                        type="text"
                        name="storeCity"
                        value={formData.storeCity}
                        onChange={handleChange}
                        required
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Nom du contact</label>
                    <input
                        type="text"
                        name="contactName"
                        value={formData.contactName}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Téléphone</label>
                    <input
                        type="text"
                        name="contactPhone"
                        value={formData.contactPhone}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Commentaire (facultatif)</label>
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <button type="submit" className="btn btn-primary">VALIDER</button>
            </form>
            {showModal && (
                <Modal contentModal="Message envoyé avec succès !" hide={hideModal} />
            )}
        </div>
    );
};

export default ContactForm;
