import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Papa from 'papaparse';
import ImportCsvExcel from '../components/ImportCsv';
import { useSelector } from 'react-redux';
import AdminCreateUser from '../components/AdminCreateUser';

/**
 * @file
 * @module Pages/AdminUsers
 * @description AdminUsers est une page qui une liste de tous les utilisateurs de l'application.
 * - Elle permet à un administrateur de voir tous les utilisateurs, de les filtrer et de les exporter en CSV.
 * - Elle permet également à l'administrateur de vérifier et de mettre à jour les utilisateurs.
 * - Elle permet également à l'administrateur de supprimer des utilisateurs sélectionnés.
 * - Cette page utilise le hook useState de React pour gérer l'état des utilisateurs, de la recherche, des colonnes et des utilisateurs sélectionnés.
 * - Cette page utilise le hook useEffect de React pour appeler l'API lors du chargement de la page.
 * - Cette page utilise le hook useSelector de 'react-redux' pour accéder au token de l'utilisateur dans le store Redux.
 * - Cette page utilise la bibliothèque PapaParse pour exporter les utilisateurs en CSV.
 * - Cette page utilise la méthode fetch de JavaScript pour appeler l'API.
 * - Cette page utilise la méthode filter de JavaScript pour filtrer les utilisateurs.
 */

const apiPrefix = process.env.REACT_APP_API_PREFIX;

const UserCount = ({ count }) => (
  <div style={{ marginBottom: '20px', fontWeight: 'bold' }}>
    Nombre d'utilisateurs affichés : {count}
  </div>
);

const AdminUsers = () => {
  const token = useSelector((state) => state.user.token);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [column, setColumn] = useState('all');
  const [selectedUsers, setSelectedUsers] = useState([]); // État pour les utilisateurs sélectionnés
  const [currentPage, setCurrentPage] = useState(1); // Page actuelle
  const [usersPerPage] = useState(50); // Nombre d'utilisateurs par page

  // Fonction pour sélectionner tous les utilisateurs affichés
  const handleSelectAllUsers = () => {
    const currentUsersIds = currentUsers.map(user => user.id);
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.length === currentUsersIds.length ? [] : currentUsersIds
    );
  };


  // Fonction pour sélectionner/désélectionner les utilisateurs
  const handleSelectUser = (id) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.includes(id)
        ? prevSelectedUsers.filter(userId => userId !== id)
        : [...prevSelectedUsers, id]
    );
  };

  const checkAndUpdateUsers = async (users) => {
    console.log("Début de la vérification et de la mise à jour des utilisateurs");
    console.log("Utilisateurs à vérifier :", users);
    
    for (let user of users) {
      console.log(`Vérification de l'utilisateur avec ID ${user.id} et SIRET ${user.siret}`);
      try {
        const response = await fetch(`https://api.hapluspme.com/v1/3EA50081-D5B9-4DD3-8136-0A94B5DFF39F/adherents/${user.siret}`);
        const data = await response.json();
  
        console.log(`Statut de l'utilisateur ${user.id} reçu :`, data.status);
  
        if (data.status === 2) {
          console.log(`Mise à jour de l'utilisateur ${user.id} en tant qu'inactif`);
          
          const updateResponse = await fetch(apiPrefix + `admin/update/${user.id}`, {
            method: 'PUT',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              ...user,
              active: false,
            }),
          });
  
          if (!updateResponse.ok) {
            console.error(`Erreur lors de la mise à jour de l'utilisateur ${user.id}`);
            throw new Error(`Erreur lors de la mise à jour de l'utilisateur ${user.id}`);
          } else {
            console.log(`Utilisateur ${user.id} mis à jour avec succès`);
          }
        } else {
          console.log(`L'utilisateur ${user.id} est déjà actif, aucune mise à jour nécessaire`);
        }
      } catch (error) {
        console.error(`Erreur lors de la vérification de l'utilisateur ${user.id}:`, error);
      }
    }
    
    console.log("Fin de la vérification et de la mise à jour des utilisateurs");
  };
  

  const handleCheckAndUpdateUsers = async () => {
    const filteredUsers = filterUsers(users, search, column);
    await checkAndUpdateUsers(filteredUsers);
    const response = await fetch(apiPrefix + 'users', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Erreur lors de la récupération des utilisateurs après mise à jour');
    }

    const updatedUsers = await response.json();
    setUsers(updatedUsers);
  };

  const handleDeleteSelectedUsers = async () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer les utilisateurs sélectionnés ?')) {
      for (let id of selectedUsers) {
        try {
          const response = await fetch(apiPrefix + `users/${id}`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            throw new Error(`Erreur lors de la suppression de l'utilisateur avec l'ID ${id}`);
          }

          // Mise à jour de l'état pour supprimer les utilisateurs supprimés
          setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
        } catch (error) {
          console.error(`Erreur lors de la suppression de l'utilisateur ${id}:`, error);
        }
      }

      // Réinitialisation des utilisateurs sélectionnés après la suppression
      setSelectedUsers([]);
    }
  };

  const parseDate = (dateString) => {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/').map(Number);
    const [hours, minutes, seconds] = timePart ? timePart.split(':').map(Number) : [0, 0, 0];
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  const filterUsers = (users, search, column) => {
    const searchTerms = search.toLowerCase().split(' ').filter(term => term);
  
    return users.filter(user => {
      return searchTerms.some(term => {
        if (column === 'all') {
          return Object.values(user).some(value => {
            if (typeof value === 'string' && value.toLowerCase().includes(term)) {
              return true;
            }
            if (typeof value === 'number' && value.toString().includes(term)) {
              return true;
            }
            if ((column === 'creationDate' || column === 'lastactive') && typeof value === 'string') {
              const userDate = new Date(value);
              const searchDate = parseDate(term);
              return userDate.toLocaleDateString() === searchDate.toLocaleDateString();
            }
            return false;
          });
        } else if (column === 'active') {
          // Vérifie si "Actif" correspond à la valeur recherchée
          const isActive = user.active ? 'oui' : 'non';
          return isActive.includes(term);
        } else if (column === 'is_admin') {
          // Vérifie si "Admin" correspond à la valeur recherchée
          const isAdmin = user.is_admin ? 'oui' : 'non';
          return isAdmin.includes(term);
        } else {
          if ((column === 'creationDate' || column === 'lastactive') && user[column]) {
            const userDate = new Date(user[column]);
            const searchDate = parseDate(term);
            return userDate.toLocaleDateString() === searchDate.toLocaleDateString();
          }
          return user[column] && user[column].toString().toLowerCase().includes(term);
        }
      });
    });
  };
  
  

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(apiPrefix + 'users', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des utilisateurs');
        }

        const data = await response.json();
        console.log(data)
        if (!Array.isArray(data)) {
          console.error('La réponse de l\'API n\'est pas un tableau :', data);
          return;
        }

        setUsers(data);
      } catch (error) {
        console.error('Erreur:', error);
      }
    };

    fetchUsers();
  }, [token]);

  const filteredUsers = search.trim() === '' ? users : filterUsers(users, search, column);

  const exportToCsv = () => {
    const csv = Papa.unparse(filteredUsers);
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const csvURL = window.URL.createObjectURL(csvData);
    let tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'filtered_users.csv');
    tempLink.click();
  };

  // Gestion de la pagination
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className='AdminUserscontainer'>
      <h1>Tous les utilisateurs</h1>
      <ImportCsvExcel />

      <UserCount count={filteredUsers.length} />

      {/* Bouton pour vérifier et mettre à jour les utilisateurs */}
      <button onClick={handleCheckAndUpdateUsers}>
        Vérifier et mettre à jour les utilisateurs
      </button>

      {/* Bouton pour exporter les utilisateurs en CSV */}
      <button onClick={exportToCsv}>
        Exporter en CSV
      </button>

      {/* Bouton pour supprimer les utilisateurs sélectionnés */}
      <button onClick={handleDeleteSelectedUsers} disabled={selectedUsers.length === 0}>
        Supprimer les utilisateurs sélectionnés
      </button>

      <AdminCreateUser/>

      <div className='filter-container'>
        <div className='filter-capsule'>
          <label htmlFor="column">Filtrer par colonne :</label>
          <select value={column} onChange={e => setColumn(e.target.value)}>
            <option value="all">Tous les champs</option>
            <option value="id">ID</option>
            <option value="username">Username</option>
            <option value="prenom">Prénom</option>
            <option value="nom">Nom</option>
            <option value="active">Actif</option>
            <option value="is_admin">Admin</option>
            <option value="siret">Siret</option>
            <option value="company">Company</option>
            <option value="lastactive">Last Activity</option>
            <option value="creationDate">Date de création</option>
            <option value="chefEntreprise">Chef d'entreprise</option>
          </select>
          <label htmlFor="search">Rechercher :</label>
          <input
            type="text"
            placeholder="Rechercher un utilisateur"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </div>

        <div className='table-container'>
          <table>
            <thead>
              <tr>
              <th>
                  <input
                    type="checkbox"
                    checked={selectedUsers.length === currentUsers.length && currentUsers.length > 0}
                    onChange={handleSelectAllUsers}
                  />
                </th>
                <th>ID</th>
                <th>Username</th>
                <th>Prénom</th>
                <th>Nom</th>
                <th>Actif</th>
                <th>Admin</th>
                <th>Siret</th>
                <th>Company</th>
                <th>Date de création</th>
                <th>Last Activity</th>
                <th>Chef d'entreprise</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map(user => (
                <tr key={user.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedUsers.includes(user.id)}
                      onChange={() => handleSelectUser(user.id)}
                    />
                  </td>
                  <td><Link to={`/admin/users/${user.id}`}>{user.id}</Link></td>
                  <td><Link to={`/admin/users/${user.id}`}>{user.username}</Link></td>
                  <td><Link to={`/admin/users/${user.id}`}>{user.firstname}</Link></td>
                  <td>{user.lastname}</td>
                  <td>{user.active ? 'Oui' : 'Non'}</td>
                  <td>{user.is_admin ? 'Oui' : 'Non'}</td>
                  <td>{user.siret}</td>
                  <td>{user.company}</td>
                  <td>{user.creationDate ? new Date(user.creationDate).toLocaleString() : "Pas de date"}</td>
                  <td>{user.lastactive ? new Date(user.lastactive).toLocaleString() : "Pas d'activité"}</td>
                  <td>{user.chefEntreprise ? 'Oui' : 'Non'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      <div className='pagination'>
        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
          Précédent
        </button>
        <button onClick={() => paginate(currentPage + 1)} disabled={indexOfLastUser >= filteredUsers.length}>
          Suivant
        </button>
      </div>
    </div>
  );
};

export default AdminUsers;
