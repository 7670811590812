import '@fortawesome/react-fontawesome';

    /**
     * @module Components/HidePasswordIcon
    * @description Afficher ou masquer le mot de passe
    * - Il utilise le hook useState de 'react' pour afficher ou masquer le mot de passe.
    * - Il renvoie un bouton qui affiche ou masque le mot de passe.
    * - Il utilise la bibliothèque 'react-fontawesome' pour afficher les icônes.
     */
    
export default function HidePasswordIcon({ showPassword, setShowPassword }) {



const handleShowPassword = () => {
    setShowPassword(!showPassword);
}


    return (

    <button onClick={handleShowPassword} id='HideShowPassword' type='button'>
{            showPassword === false ?
    <i className="fa-regular fa-eye"></i>
    :
    <i className="fa-regular fa-eye-slash"></i>}
    </button >
)
}