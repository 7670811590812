import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateOptIn } from '../feature/user.Slice'; 
import { clearUser } from '../feature/user.Slice'; 

/**
 * @module Components/Optin
 * @description OptIn est un composant qui permet à l'utilisateur de s'inscrire ou de se désinscrire de la liste de diffusion.
 * - Il utilise le hook useDispatch de 'react-redux' pour dispatcher l'action updateOptIn.
 * - Lorsque l'utilisateur coche ou décoche la case, l'action updateOptIn est dispatchée,
 * ce qui met à jour l'état optIn de l'utilisateur dans le store Redux.
 * - Ensuite, une requête HTTP POST est envoyée à l'API pour mettre à jour l'état optIn de l'utilisateur dans la base de données.
 *
 */

const apiPrefix = process.env.REACT_APP_API_PREFIX;

const OptIn = ({ username, optIn }) => {
    const dispatch = useDispatch();
    const [optInState, setOptInState] = useState(optIn);

    useEffect(() => {
        setOptInState(optIn);
    }, [optIn]);

    const handleOptInChange = async (e) => {
        const newOptIn = e.target.checked;
       // console.log('Opt-in status changing to:', newOptIn); // Debugging log
        setOptInState(newOptIn);
        dispatch(updateOptIn(newOptIn));

        const url = newOptIn ? `${apiPrefix}optin/create-contact-optin` : `${apiPrefix}optin/delete-contact-optin`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: username })
            });

            if (!response.ok) {
                dispatch(clearUser());
                const data = await response.json();
                console.error(data.error);
            }
        } catch (error) {
            console.error('Une erreur est survenue lors de la modification de la liste de communication.');
        }
    };

    return (
        <div>
            <label>
                <input type="checkbox" checked={optInState} onChange={handleOptInChange} />
                Je m’abonne
            </label>
        </div>
    );
};

export default OptIn;