import React from 'react';
import { useDispatch } from 'react-redux';
import { clearUser } from '../feature/user.Slice'; 


/**
 * @module Components/LogOut
 * @description Logout est un composant qui permet à l'utilisateur de se déconnecter.
 * - Il utilise le hook useDispatch de 'react-redux' pour dispatcher l'action clearUser.
 * - Lorsque l'utilisateur clique sur le bouton 'Se déconnecter', l'action clearUser est dispatchée,
 * ce qui efface les informations de l'utilisateur du store Redux.
 *
 */

const Logout = () => {
    const dispatch = useDispatch();

    const handleLogout = () => {
        // Dispatch l'action clearUser
        dispatch(clearUser());
    };

    return (
        <div>
            <button className='Deco' onClick={handleLogout}>Se déconnecter</button>
        </div>
    );
};

export default Logout;