import React, { useState, useRef } from 'react';
import CryptoJS from 'crypto-js';
import Modal from 'fast-modal-library';

/**
 * @file
 * @module Pages/AdminCreateSignupPage
 * @description AdminCreateSignupPage est une page qui permet à un administrateur de créer une URL de création de compte.
 * - L'administrateur entre le nom de l'entreprise et le SIRET, puis une URL est générée.
 * - Cette URL est ensuite affichée à l'administrateur, qui peut la copier dans le presse-papiers.
 * - L'URL contient le nom de l'entreprise et le SIRET chiffrés en AES et encodés pour être sûrs dans une URL.
 * - Lorsqu'un utilisateur accède à cette URL, le nom de l'entreprise et le SIRET sont déchiffrés et utilisés pour remplir le formulaire de création de compte.
 * - Cette page utilise la bibliothèque CryptoJS pour le chiffrement AES.
 * - Cette page utilise le hook useState de React pour gérer l'état des champs de saisie et de l'URL générée.
 * - Cette page utilise le hook useRef de React pour accéder au champ de saisie de l'URL et le sélectionner pour la copie.
 * - Cette page utilise la fonction encodeURIComponent de JavaScript pour encoder les données dans l'URL.
 * - Cette page utilise la méthode execCommand de JavaScript pour copier le contenu du champ de saisie dans le presse-papiers.
 */

const apiPrefix = process.env.REACT_APP_API_PREFIX;

const AdminCreateSignupPage = () => {
    const [entreprise, setEntreprise] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState('');
    const [siret, setSiret] = useState('');
    const [url, setUrl] = useState('');
    const [emails, setEmails] = useState('');

    const urlRef = useRef(null);

    const handleCopyUrl = () => {
        urlRef.current.select();
        document.execCommand('copy');
    };

    const hide = () => {
        setShowModal(false);
    };

    const handleCreateUrl = () => {
        const encryptedEntreprise = CryptoJS.AES.encrypt(entreprise, 'secret key 123').toString();
        const encryptedSiret = CryptoJS.AES.encrypt(siret, 'secret key 123').toString();
    
        // console.log("Entreprise chiffrée: ", encryptedEntreprise);
        // console.log("Siret chiffré: ", encryptedSiret);
    
        const urlSafeEntreprise = encodeURIComponent(encryptedEntreprise);
        const urlSafeSiret = encodeURIComponent(encryptedSiret);
        
        setUrl(`https://ca-cpourmoi.com/signup/${urlSafeEntreprise}/${urlSafeSiret}`);
        // setUrl(`https://thankful-sky-065cfd303.5.azurestaticapps.net/signup/${urlSafeEntreprise}/${urlSafeSiret}`);
    };

    const handleSendInvite = async () => {
        const emailList = emails.split(',').map(email => email.trim());
        try {
            const responses = await Promise.all(emailList.map(email => 
                fetch(apiPrefix +'send-invite', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email,
                        url
                    }),
                })
            ));

            const allSuccessful = responses.every(response => response.ok);

            if (allSuccessful) {
                setMessage('Invitations envoyées avec succès');
                setShowModal(true);
            } else {
                setMessage('Erreur lors de l\'envoi des invitations');
                setShowModal(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="admin-create-page">
            <h1>Créer une URL de création de compte</h1>
            <p>Entrez le nom de l'entreprise et le SIRET pour créer une URL de création de compte</p>
            <input className='input-field'
                type="text"
                value={entreprise}
                onChange={(e) => setEntreprise(e.target.value)}
                placeholder="Entreprise"
            />
            <input className='input-field'
                type="text"
                value={siret}
                onChange={(e) => setSiret(e.target.value)}
                placeholder="SIRET"
            />
            <button onClick={handleCreateUrl}>Créer URL</button>

        {url && (
            <>
                <div className="url-container">
                    <input
                        ref={urlRef}
                        className="url-display"
                        value={url}
                        readOnly
                    />
                    <button className="copy-button" onClick={handleCopyUrl}>Copier</button>
                </div>
                <div className="url-container">
                    <h2>Envoyer une invitation par e-mail</h2>
                    <input className='input-field'
                        type="text"
                        value={emails}
                        onChange={(e) => setEmails(e.target.value)}
                        placeholder="Emails (séparés par des virgules)"
                    />
                    <button onClick={handleSendInvite}>Envoyer les invitations</button>
                </div>
                {message && message.startsWith('Erreur') && <p className='error-message'>{message}</p>}
            {showModal && (
                <Modal contentModal={message} hide={hide} />
            )}
            </>
        )}
        </div>
    );
};

export default AdminCreateSignupPage;