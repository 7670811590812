import{ useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearUser } from '../feature/user.Slice'; 

/**
 * @module Components/Stats
 * @description Stats est un composant qui affiche un tableau de statistiques des utilisateurs.
 * les statistiques affichées sont les suivantes:
 * - Nombre d'utilisateurs inscrits ce mois-ci
 * - Nombre d'utilisateurs inscrits et connectés ce mois-ci
 * - Nombre d'utilisateurs connectés ce mois-ci
 */


export default function Stats() {
    const apiPrefix = process.env.REACT_APP_API_PREFIX;
    const token = useSelector((state) => state.user.token);
    const [users, setUsers] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
    /**
     * Récupère la liste des utilisateurs
     */
      const fetchUsers = async () => {
          try {
              const response = await fetch(apiPrefix + 'users', {
                  method: 'GET',
                  headers: {
                      'Authorization': `Bearer ${token}`, 
                      'Content-Type': 'application/json',
                  },
              });

              if (!response.ok) {
                  dispatch(clearUser());
                  throw new Error('Erreur lors de la récupération des utilisateurs');
              }

              const data = await response.json();
              setUsers(data);
          } catch (error) {
              console.error('Erreur:', error);
          }
      };

      fetchUsers();
  }, [apiPrefix, token]);
      
const date = new Date();
const currentMonth = date.getMonth();
const currentYear = date.getFullYear();


/**
 * Filtre les utilisateurs inscrits ce mois-ci
 */
const userConnectedThisMonth = users.filter((user) => {
    const lastActive = new Date(user.lastactive);
    return lastActive.getMonth() === currentMonth && lastActive.getFullYear() === currentYear;
})

/**
 * Filtre les utilisateurs inscrits et connectés ce mois-ci
 */
const userCreatedThisMonth = users.filter((user) => {
    const creationDate = new Date(user.creationDate);
    return creationDate.getMonth() === currentMonth && creationDate.getFullYear() === currentYear;
})

/**
 * Filtre les utilisateurs connectés ce mois-ci
 */
const createdActiveThisMonth = users.filter((user) => {
    const creationDate = new Date(user.creationDate);
    const lastActive = new Date(user.lastactive);
    return creationDate.getMonth() === currentMonth && creationDate.getFullYear() === currentYear && lastActive.getMonth() === currentMonth && lastActive.getFullYear() === currentYear;
})




      return (
        <table className='stats-table'>
        <thead>
          <tr>
            <th>Inscription / Mois</th>
            <th>Inscription / Connexion / Mois</th>
            <th>Connexion / Mois</th>
          </tr>
        </thead>
        <tbody>
            <tr>
                <td>{userCreatedThisMonth.length}</td>
                <td>{createdActiveThisMonth.length}</td>
                <td>{userConnectedThisMonth.length}</td>
            </tr>
        </tbody>
        </table>
      );
}