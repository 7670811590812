import React from 'react';
import { createRoot } from 'react-dom/client';
//import ReactDOM from 'react-dom'
import { Provider } from "react-redux"
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import store, { persistor } from "./app/store"
import "./app/styles/_index.scss"

//const root = ReactDOM.createRoot(document.getElementById('root'));

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);