import React from 'react';
import { NavLink } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import logoF from "../asset/LogoBlanc.webp";
/**
 * @module Components/Footer
 * @description Footer est un composant qui affiche le pied de page de l'application.
 * Il contient des informations de contact, un logo et des liens vers les pages "Mentions légales" et "CGU".
 * Il affiche également l'année actuelle.
 */

const Footer = () => {
    const year = new Date().getFullYear();
    
    return (
        <div className='Footer'>
            <div className='footer-section logo-section'>
                <img src={logoF} alt="LogoCCPMFooter" />
            </div>
            <div className='footer-section'>
                <ul>
                    <li>
                        <NavLink to="/Profil">
                            <i className="fas fa-user"></i> Mon compte
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/ContactForm">
                            <i className="fas fa-pencil-alt"></i> Référencer une marque
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/ContactForm">
                            <i className="fas fa-envelope"></i> Contact
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div className='footer-section'>
                <ul>
                    <li>
                        <a href='/MentionsLegales.docx' download='MentionsLegales.docx'>
                            <i className="fas fa-file-alt"></i> Mentions légales
                        </a>
                    </li>
                    <li>
                        <a href='/CGUCCPM.docx' download='CGUCCPM.docx'>
                            <i className="fas fa-file-alt"></i> CGU
                        </a>
                    </li>
                    <li>
                        <a href='/CGVCCPM.docx' download='CGVCCPM.docx'>
                            <i className="fas fa-file-alt"></i> CGV
                        </a>
                    </li>
                </ul>
            </div>
            <div className='footer-bottom'>
                <p>© {year} par Ça, c’est pour Moi !. Tous droits réservés.</p>
            </div>
        </div>
    );
};

export default Footer;
