import React, { useState } from 'react';
import { useSelector } from 'react-redux';


                /**
                * @module Components/Autologin
                * @description Composant permettant de rediriger l'utilisateur vers une URL avec un token d'authentification
                * - Le composant prend en paramètre l'URL de redirection.
                * - Il utilise le hook useSelector de 'react-redux' pour accéder à l'ID de l'utilisateur dans le store Redux.
                * - Il utilise le hook useState de React pour gérer l'état de l'erreur.
                * - Il utilise la fonction fetch pour appeler l'API et récupérer un token d'authentification.
                * - Lorsque l'utilisateur clique sur le bouton 'Je découvre !', le composant appelle l'API pour obtenir un token,
                * puis redirige l'utilisateur vers l'URL spécifiée avec le token.
                * - Si une erreur se produit lors de l'appel à l'API, elle est affichée à l'utilisateur.
                * - Le composant affiche un bouton 'Je découvre !' pour permettre à l'utilisateur de déclencher la redirection.
                */
const AutoRedirect = ({ redirectUrl }) => {
    const apiPrefix = process.env.REACT_APP_API_PREFIX;
    
    const [error, setError] = useState(null);
    const userId = useSelector(state => state.user.user.user.id);

    const fetchToken = async () => {
        try {
            const response = await fetch(`${apiPrefix}autologin/token/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch token');
            }
            const data = await response.json();
            return data.token;
        } catch (error) {
            setError(error.message);
        }
    };

    const handleRedirect = async () => {
        try {
            const token = await fetchToken();
            if (token) {
                if (redirectUrl.includes('app.reducce.fr')) {
                    // Redirection dans le même onglet avec l'URL et l'organisation
                    window.location.href = `${redirectUrl}${token}?organisation=CA-CEST-POUR-MOI`;
                } else {
                    // Ouvre dans un nouvel onglet pour les autres URL
                    const finalUrl = `${redirectUrl}${token}`;
                    window.open(finalUrl, '_blank');
                }
            } else {
                throw new Error('Failed to fetch token');
            }
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div>
            {error && <p>Error: {error}</p>}
            <button className='cta-button' onClick={handleRedirect}>Je découvre !</button>
        </div>
    );
};

export default AutoRedirect;
