import React from 'react';
import { NavLink } from 'react-router-dom';

/**
 * @file
 * @module Pages/Error
 * @description Error est une page qui affiche un message d'erreur lorsque l'utilisateur tente d'accéder à une page qui n'existe pas.
 * Elle affiche un message d'erreur 404 et un lien pour retourner à la page d'accueil.
 */

const Error = () => {
    return (
        <div>
            <div className='container-error'>
                <h1>404</h1>
                <h2>Oups! La page que vous demandez n'existe pas</h2>
                <NavLink to={"/"} className="link">Retourner sur la page d'accueil</NavLink>
            </div>
        </div>
    );
};

export default Error;