import React, { useEffect, useState } from 'react';

/**
 * @file
 * @module Pages/BounceTable
 * @description BounceTable est une page qui affiche un tableau des retour de brevo.
 * Les données affichées sont les suivantes:
 * - Email
 * - Type
 * - Raison
 * - Tag
 * - Date
 */
const apiPrefix = process.env.REACT_APP_API_PREFIX;

const BounceTable = () => {
    const [bounces, setBounces] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Utilisation de fetch pour récupérer les bounces
        fetch(apiPrefix + `api/brevo/bounces`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setBounces(data);
                setLoading(false);
                console.log(data);
            })
            .catch(error => {
                console.error('Error fetching bounces:', error);
                setLoading(false);
            });
    }, []);



    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <>
        <h3>il y a {bounces.length} e-mail qui n'ont pas pu être livrés</h3>
        <table>
            <thead>
                <tr>
                    <th>Email</th>
                    <th>type</th>
                    <th>Raison</th>
                    <th>Tag</th>
                    <th>Date</th>
                    
                </tr>
            </thead>
            <tbody>
                {bounces.map(bounce => (
                    <tr key={bounce.id}>
                        <td>{bounce.email}</td>
                        <td>{bounce.event_type}</td>
                        <td>{bounce.reason}</td>
                        <td>{bounce.tag}</td>
                        <td>{new Date(bounce.timestamp).toLocaleString()}</td>
                        
                    </tr>
                ))}
            </tbody>
        </table>
        </>
    );
};

export default BounceTable;
