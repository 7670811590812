import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logout from '../components/LogOut'; 
import logo from "../asset/logo.webp"

/**
 * @module Components/Header
 * @description Header est un composant qui affiche le logo et la barre de navigation en haut de l'application.
 * - Il contient des liens de navigation vers différentes pages de l'application.
 * - Il utilise le composant NavLink de 'react-router-dom' pour créer ces liens.
 * - Il utilise le hook useSelector de 'react-redux' pour accéder à l'état de l'utilisateur dans le store Redux.
 * 
 */

const Header = () => {
    // const user = useSelector((state) => state.user);
    const isLoggedIn = useSelector(state => state.user.isLoggedIn);

    return (
        
        <header className='Header'>
            <NavLink to="/" >
                <img src={logo} className="logo" alt="CCPM logo" />
            </NavLink>
            <div className='navigation'>
                {isLoggedIn ? (
                    <>
                        <NavLink to={"/"} className={(nav) => (nav.isActive ? 'nav-active' : 'nav')}>Accueil</NavLink>
                        {/* <NavLink to={"/Staff"} className={(nav) => (nav.isActive ? 'nav-active' : 'nav')}>View Current Employees</NavLink> */}
                        <NavLink to={"/Profil"} className={(nav) => (nav.isActive ? 'nav-active' : 'nav')}>Mon profil</NavLink>
                        <Logout /> {/* Utiliser le composant Logout */}
                    </>
                ) : (
                    <>
                        <NavLink to={"/Signin"} className={(nav) => (nav.isActive ? 'nav-active' : 'nav')}>Se connecter</NavLink>
                        {/* <NavLink to={"/Signup"} className={(nav) => (nav.isActive ? 'nav-active' : 'nav')}>S'enregistrer</NavLink> */}
                    </>
                )}
            </div>
        </header>
    );
};

export default Header;