import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'; 

/**
 * @module Components/IsAdminHeader
 * @description IsAdminHeader est un composant qui affiche un lien vers le tableau de bord si l'utilisateur est un administrateur.
 * - Il utilise le hook useSelector de 'react-redux' pour accéder à l'état de l'utilisateur dans le store Redux.
 * - Si l'utilisateur est un administrateur (user.is_admin est vrai), il affiche un lien vers '/dashboard'.
 * - Sinon, il ne renvoie rien et n'affiche donc rien.
 */

const IsAdminHeader = () => {
    const user = useSelector((state) => state.user.user.user);
    //console.log(user);

    if (user.is_admin) {
        return (
            <div className='isAdmin'>
                <Link className='' to="/dashboard">Tableau de bord</Link> {/* Ajoutez le lien ici */}
            </div>
        );
    }

    return null;
};

export default IsAdminHeader;