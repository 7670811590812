

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  isLoggedIn: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.token = action.payload.token;
      state.isLoggedIn = true;
    },
    clearUser: (state) => {
      state.user = null;
      state.token = null;
      state.isLoggedIn = false;
    },
    updateUser: (state, action) => {
      state.user = { user: action.payload };
  }, updateOptIn: (state, action) => {
    if (state.user.user) {
      state.user.user.optin = action.payload;
    }
  },
  checkTokenExpiration: (state) => {
    const token = state.token;
    if (!token) return initialState;
  
    const jwtToken = JSON.parse(atob(token.split('.')[1]));
  
    // vérifie si le token a expiré
    const currentTime = Date.now().valueOf() / 1000;
    const isTokenExpired = currentTime > jwtToken.exp;
  
    if (isTokenExpired) {
      return initialState;
    }
  },
},
});

export const { setUser, clearUser, updateUser, updateOptIn, checkTokenExpiration } = userSlice.actions;

export const selectUser = (state) => state.user.user;
export const selectToken = (state) => state.user.token;

export default userSlice.reducer;